import {
  createRouter,
  createWebHashHistory,
} from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import ( /* webpackChunkName: "indexWrapper" */ "../views/indexWrapper.vue"),
    redirect: "/",
    children:[
      {
        path: "/",
        name: "home",
        component: () => import ( /* webpackChunkName: "home" */ "../views/home/home.vue"),
      },
      {
        path: "/invoice",
        name: "invoice",
        component: () => import ( /* webpackChunkName: "home" */ "../views/home/invoice.vue"),
      }
    ]
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { left: 0, top: 0 };
  }
}


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior,
  routes
})

export default router
