import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import messages from './lang'

const language = localStorage.getItem('lang') || 'en'
localStorage.setItem('lang', language)


const i18n = createI18n({
  allowComposition: true,
  globalInjection: true,
  legacy: false, // you must specify 'legacy: false' option
  locale: language,
  messages,
});

export default i18n
