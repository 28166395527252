export default {
  home: {
    fy1: '說明一',
    fy2: '此頁面僅為方便測試和功能展示使用！ ',
    fy3: '測試訂單的金額是固定的，為了避免系統資源的浪費和安全，設定在10~100$，隨時可能調整，望理解！ ',
    fy4: '完整流程',
    fy5: '極簡流程',
    fy6: '自動歸集',
    fy7: '正常支付',
    fy8: '指定貨幣付款',
    fy9: '建立發票 (付款金額：$20)',
    fy10: '說明二',
    fy11: '取消',
    fy12: '確定',
    fy13: '請選擇您將從何處付款。 ',
    fy14: '請從以下貨幣選項中選擇一個以完成您的付款。 ',
    fy15: '應付金額',
    fy16: '連接你的錢包',
    fy17: '支援 300+ Web3錢包。 手機錢包或瀏覽器擴充錢包。 ',
    fy18: '已連線',
    fy19: '連接錢包',
    fy20: '聯絡資訊',
    fy21: '請提供您的電子郵件信箱來',
    fy22: '接收付款通知。 ',
    fy23: '請輸入郵件地址',
    fy24: '提交',
    fy25: '我同意以此信箱來接收我的支付帳單',
    fy26: '電子郵件必須有效',
    fy27: '連接錢包',
    fy28: '確認支付',
    fy29: '斷開連線',
    fy30: '提交成功',
    fy31: '返回',
    fy32: '下一步',
  }
}