export default {
  home: {
    fy1: 'Giải thích 1',
    fy2: 'Trang này chỉ dành cho thử nghiệm và hiển thị chức năng!',
    fy3: 'Số lượng lệnh kiểm tra là cố định. Để tránh lãng phí tài nguyên hệ thống và an toàn, nó được đặt ở mức 10~100$. Nó có thể được điều chỉnh bất cứ lúc nào. Mong bạn thông cảm! ',
    fy4: 'Hoàn tất quá trình',
    fy5: 'Quy trình tối giản',
    fy6: 'Thu thập tự động',
    fy7: 'Thanh toán bình thường',
    fy8: 'Thanh toán bằng loại tiền được chỉ định',
    fy9: 'Tạo hóa đơn (số tiền thanh toán: $20)',
    fy10: 'Giải thích 2',
    fy11: 'Hủy',
    fy12: 'Được',
    fy13: 'Vui lòng chọn nơi bạn sẽ thanh toán. ',
    fy14: 'Vui lòng chọn một trong các tùy chọn tiền tệ sau để hoàn tất thanh toán của bạn. ',
    fy15: 'Số tiền phải trả',
    fy16: 'Kết nối ví của bạn',
    fy17: 'Hỗ trợ hơn 300 ví Web3. Ví di động hoặc ví mở rộng trình duyệt. ',
    fy18: 'Đã kết nối',
    fy19: 'Kết nối ví',
    fy20: 'Thông tin liên hệ',
    fy21: 'Xin vui lòng cung cấp địa chỉ email của bạn',
    fy22: 'Nhận thông báo thanh toán. ',
    fy23: 'Xin vui lòng nhập địa chỉ email của bạn',
    fy24: 'Gửi',
    fy25: 'Tôi đồng ý nhận thanh toán hóa đơn của mình tới địa chỉ email này',
    fy26: 'Email phải hợp lệ',
    fy27: 'Kết nối ví',
    fy28: 'Xác nhận thanh toán',
    fy29: 'Ngắt kết nối',
    fy30: 'Gửi thành công',
    fy31: 'trở về',
    fy32: 'Tiếp theo',
  }
}