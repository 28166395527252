<template>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</template>

<script setup>

</script>

<style lang="scss">
#app {
  font-family: PingFangSC-Semibold, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background: #ffffff;
  height: 100%;
  color: #fff;
}
</style>
