export default {
  home: {
    fy1: 'Explanation 1',
    fy2: 'This page is only for testing and function display! ',
    fy3: 'The amount of the test order is fixed. In order to avoid the waste of system resources and safety, it is set at 10~100$. It may be adjusted at any time. Hope you understand! ',
    fy4: 'Complete process',
    fy5: 'Minimalist process',
    fy6: 'Automatic collection',
    fy7: 'Normal payment',
    fy8: 'Payment in designated currency',
    fy9: 'Create invoice (payment amount: $20)',
    fy10: 'Explanation 2',
    fy11: 'Cancel',
    fy12: 'OK',
    fy13: 'Please select where you will pay from. ',
    fy14: 'Please select one of the following currency options to complete your payment. ',
    fy15: 'Amount payable',
    fy16: 'Connect your wallet',
    fy17: 'Supports 300+ Web3 wallets. Mobile wallet or browser extension wallet. ',
    fy18: 'Connected',
    fy19: 'Connect wallet',
    fy20: 'Contact information',
    fy21: 'Please provide your email address',
    fy22: 'Receive payment notification. ',
    fy23: 'Please enter your email address',
    fy24: 'Submit',
    fy25: 'I agree to receive my bill payment to this email address',
    fy26: 'Email must be valid',
    fy27: 'Connect wallet',
    fy28: 'Confirm payment',
    fy29: 'Disconnect',
    fy30: 'Submission successful',
    fy31: 'Return',
    fy32: 'Next',
  }
}