import china from "./china"
import english from "./english"
import vietnam from "./vietnam";

export default{
  'en':english,  //英语
  'zh':china,  //繁体
  'vn':vietnam,  //越南
}


