import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia';
import Vant from "vant";
import './assets/fonts/iconfont.css';
import "vant/lib/index.css";
import moment from "moment";
import i18n from "./i18n";

import 'animate.css';
import router from './router';
import gameUtils from 'awesome-game-utils'
gameUtils.rem(document, window, 750);
gameUtils.stopZoom();
const store = createPinia();
gameUtils.rem(document, window, 375);
const app = createApp(App);

let h = window.innerHeight;
let w = Math.floor(h / 2);
if (!gameUtils.adaptPC(w, h)) {
  app.config.globalProperties.$moment = moment;
  app.config.globalProperties.$gameUtils = gameUtils;
  app.use(router).use(store).use(Vant).use(i18n).mount('#app')
}
